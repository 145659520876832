import React from "react"
import { t } from "translations"

import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"

import { IPagingContext } from "Components/Layout/PageComponent/PageDataProvider"
import Link from "Components/Link"
import PortableText from "Components/PortableText/PortableText"

import Sections from "../Sections"
import ToC from "./ToC"
import { IArticle } from "data/contentData/interfaces/mongodb/IArticle"

export default function PagedSections({
    page,
    paging
}: {
    page: IPublic
    paging: IPagingContext | null
}) {
    if (!page) return null

    let currenSectionId = paging?.paging.currentPageNumber ?? 0
    if (!(page as IArticle).paginateLeadText && page.sections?.[0].type === "root")
        currenSectionId -= 1
    const currentSection = paging?.pages[currenSectionId]

    const isLastSection = currenSectionId === (paging?.pages.length ?? 0) - 1
    const isFirstSection = currenSectionId === 0
    const getHeaderVariant = isFirstSection ? "h2" : "h1"

    function getHeaderValue(currentSectionName: string) {
        if (isFirstSection) return currentSectionName

        const wordsInPage = page.name.toLowerCase().split(" ")
        if (
            wordsInPage.some(
                wordInPage =>
                    currentSectionName.toLowerCase().indexOf(wordInPage) > -1
            )
        )
            return currentSectionName

        return `${page.name}: ${currentSectionName}`
    }

    function showHeader(currentSectionName: string) {
        let result = currentSectionName ? true : false

        if (result && isFirstSection) result = page.name !== currentSectionName

        return result
    }

    return (
        <>
            {currentSection && (
                <section>
                    {currentSection.leadText && (
                        <PortableText value={currentSection.leadText} />
                    )}
                    {showHeader(currentSection.name) && (
                        <StyledHeader
                            isfirstsection={isFirstSection}
                            variant={getHeaderVariant}>
                            {getHeaderValue(currentSection.name)}
                        </StyledHeader>
                    )}
                    {!isFirstSection &&
                        <ToC
                            page={page}
                            paging={paging}
                        />}
                    <PortableText value={currentSection.blockContent} />
                    {currentSection.sections &&
                        currentSection.sections.length > 0 && (
                            <Sections
                                sections={currentSection.sections}
                                level={2}
                            />
                        )}
                </section>
            )}

            <StyledButtonWrapper>
                <StyledAbLink
                    href={getPreviousUrl(
                        page.url,
                        paging
                    )}
                    disabled={isFirstSection}>
                    <Button
                        variant="contained"
                        disabled={isFirstSection}>
                        {t.article.previousButton}
                    </Button>
                </StyledAbLink>
                <StyledAbLink
                    href={getNextUrl(
                        page.url,
                        paging
                    )}
                    disabled={isLastSection}>
                    <Button
                        variant="contained"
                        disabled={isLastSection}>
                        {t.article.nextButton}
                    </Button>
                </StyledAbLink>
            </StyledButtonWrapper>
        </>
    )
}

/**
 * get previous url path
 * @param pageUrl Get previous url path
 * @param previousPage article url
 * @returns return previous url path without page number if page number is 1
 */
function getPreviousUrl(
    pageUrl: string,
    paging: IPagingContext | null
) {
    if (!paging?.paging.prev) return pageUrl

    if (pageUrl.endsWith("/")) return `${pageUrl}${paging?.paging.prev}`

    return `${pageUrl}/${paging?.paging.prev}`
}

function getNextUrl(
    pageUrl: string,
    paging: IPagingContext | null
) {
    if (!paging?.paging.next) return pageUrl

    if (pageUrl.endsWith("/")) return `${pageUrl}${paging?.paging.next}`
    return `${pageUrl}/${paging?.paging.next}`

}

const StyledButtonWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(4)
}))

const StyledAbLink = styled(Link)(() => ({
    textDecoration: "none",
    "&:hover": {
        textDecoration: "none"
    }
}))

const StyledHeader = styled(Typography)<{ isfirstsection: boolean }>(
    ({ theme, isfirstsection }) => ({
        paddingBottom: isfirstsection ? theme.spacing(0) : theme.spacing(2)
    })
)
